import { EU_NUMBER, URL_ADMIN_LOGOUT, URL_CSRF_TOKEN, URL_EVENT_REMOVE } from './../shared/constants';
import { Injectable } from '@angular/core';
import {
  URL_REGISTER,
  URL_APPUSER,
  URL_API,
  URL_EVENT_SAVE,
  URL_PROFILE,
  URL_PASSWORD_RESET,
  URL_PASSWORD_SET,
  URL_REMOVE_ACCOUNT,
} from '../shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { CookieService } from './cookie.service';

export type UserLoginData = {
  email: string;
  password: string;
};

export type UserRegisterData = {
  email: string;
  email_confirmation: string;
  gender: string;
  infix?: string;
  last_name: string;
  name: string;
  password: string;
  password_confirmation: string;
};

@Injectable()
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private errorSubject: BehaviorSubject<string>;
  public error: Observable<string>;
  private hasEuNumberSubject: BehaviorSubject<boolean>;
  public hasEuNumber: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookies: CookieService
  ) {
    this.userSubject = new BehaviorSubject<User>(new User());
    this.errorSubject = new BehaviorSubject<string>(null);
    this.user = this.userSubject.asObservable();
    this.error = this.errorSubject.asObservable();
    this.hasEuNumberSubject = new BehaviorSubject<any>(this.hasEuNumberCheck());
    this.hasEuNumber = this.hasEuNumberSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public get isAuthenticated(): boolean {
    return Object.keys(this.userValue).length > 0;
  }

  hasEuNumberCheck(): boolean {
    return this.getEuNumber() != undefined;
  }

  getEuNumber(): string {
    return this.cookies.getItem(EU_NUMBER);
  }

  setEuNumber(euNumber: string) {
    if (euNumber) this.cookies.setItem(EU_NUMBER, euNumber);
    else this.cookies.removeItem(EU_NUMBER);

    this.hasEuNumberSubject.next(this.hasEuNumberCheck());
  }

  analyticsStatus(): 'yes' | 'no' {
    return this.hasEuNumberCheck() ? 'yes' : 'no';
  }

  hasRole(role: string): boolean {
    return this.userValue?.roles?.indexOf(role) > -1;
  }

  save(type: string, id: number, saveType: string) {
    const data = { [type]: id };
    return this.http
      .post<{ appUser: User; }>(`${URL_API}/${saveType}`, data)
      .pipe(map((response) => this.setUser(response)));
  }

  removeEvent(id: number) {
    return this.http
      .post<{ appUser: User; }>(URL_EVENT_REMOVE, { event: id })
      .pipe(map((response) => this.setUser(response)));
  }

  event(id: number) {
    return this.http
      .post<{ appUser: User; }>(URL_EVENT_SAVE, { event: id })
      .pipe(map((response) => this.setUser(response)));
  }

  /**
   * Allows the user to login to the app. Removes token used to login.
   */
  login(data: UserLoginData, loginUrl: string, authUrl: string) {
    return this.http.get(URL_CSRF_TOKEN).pipe(
      mergeMap(() => this.http.post<Object>(loginUrl, data)),
      mergeMap((response: any) => {
        if (response.g2fa !== undefined) {
          this.errorSubject.next(response);
          return this.error;
        }

        this.setEuNumber(response?.eu_number ?? null);

        return this.fetchUser(authUrl);
      })
    );
  }

  home() {
    this.router.navigate(['/']);
  }

  async logout(url: string) {
    this.setEuNumber(null);

    this.userSubject.next(new User);

    try {
      await firstValueFrom(this.http.post(URL_ADMIN_LOGOUT, null));
    } catch (e) { }

    // Check if the url is specified as admin route
    if (url.includes('admin')) {
      this.router.navigate(['/admin/login'], {
        queryParams: { returnUrl: url },
      });
    } else {
      this.router.navigate(['/'], { queryParams: { returnUrl: url } });
    }
  }

  resetPassword(data: { email: string; }) {
    return this.http.post<any>(URL_PASSWORD_RESET, data);
  }

  resetPasswordData(token: string, email: string, data) {
    return this.http.post<any>(`${URL_PASSWORD_SET}/${token}/${email}`, data);
  }

  register(data: UserRegisterData) {
    return this.http.post<Object>(URL_REGISTER, data).pipe(
      mergeMap(() => this.fetchUser(URL_APPUSER))
    );
  }

  update(data) {
    return this.http.post<any>(URL_PROFILE, data);
  }

  downloadPdf(url: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers, responseType: 'blob' });
  }

  fetchUser(authUrl: string) {
    return this.http
      .get<{ data: User; } | { appUser: User; }>(authUrl)
      .pipe(
        map((response: { data: User; } | { appUser: User; }) => this.setUser(response))
      );
  }

  setUser(data) {
    let user: User = new User(data);

    // Check if the type of user
    if ('appUser' in data) {
      user = new User(data.appUser);
      user.isAjovy = user.medications.some(
        (medication) => medication.details?.is_ajovy
      );
    } else if ('data' in data) {
      user = new User(data.data);
      user.roles.push('ROLE_ADMIN');
    }

    // Set user roles
    user.roles = user.roles ?? [];

    // Update value of each subscription
    this.userSubject.next(user);

    return user;
  }

  removeAccount() {
    return this.http.get<{ success: boolean; }>(URL_REMOVE_ACCOUNT);
  }
}
