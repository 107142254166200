import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URL_EVENTS } from '../shared/constants';
import { Pagination, RawPagination } from '../shared/models/pagination.model';
import { toFormData } from '../shared/helpers/form-data.helper';
import { Event } from 'src/app/shared/models/event.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  events: Event[];
  constructor(private http: HttpClient) { }

  getAll(
    options: { [key: string]: any; } = {},
    pagination?: Pagination<Event>
  ): Observable<RawPagination<Event>> {
    return this.http.get<RawPagination<Event>>(URL_EVENTS, {
      params: { ...pagination?.params, ...options },
    }).pipe(
      map(({ data, ...pagination }) => ({
        ...pagination,
        data: data.map(item => new Event({
          ...item,
          start_date: item.start_date + 'Z',
          end_date: item.end_date + 'Z',
        }))
      }))
    );
  }

  reorder(id: number, data: { [action: string]: number; }) {
    return this.http.post<any>(`${URL_EVENTS}/order/${id}`, {
      previous: data,
    });
  }

  getPlanned(
    options: { [key: string]: any; } = {},
    pagination?: Pagination<Event>
  ): Observable<RawPagination<Event>> {
    return this.getAll({ where: 'end_date,>=,today', ...options }, pagination);
  }

  getPast(
    options: { [key: string]: any; } = {},
    pagination?: Pagination<Event>
  ): Observable<RawPagination<Event>> {
    return this.getAll({ where: 'end_date,<,today', ...options }, pagination);
  }

  get(id: number | string): Observable<Event> {
    return this.http
      .get<any>(`${URL_EVENTS}/${id}`)
      .pipe(map(({ data }) => new Event({
        ...data,
        start_date: data.start_date + 'Z',
        end_date: data.end_date + 'Z',
      })));
  }

  create(data: Event): Observable<Event> {
    return this.http.post<Event>(URL_EVENTS, toFormData(data));
  }

  update(id: number, data: Event): Observable<Event> {
    return this.http.post<Event>(
      `${URL_EVENTS}/${id}`,
      toFormData(data, 'PUT')
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${URL_EVENTS}/${id}`);
  }
}
