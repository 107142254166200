import { JSONLDSchema } from './schema.model';
import { StripTagsPipe } from '../pipes/strip-tags.pipe';
import { ShortenPipe } from '../pipes/shorten.pipe';

export class Event extends JSONLDSchema<Event> {
  id: number;
  title: string;
  slug: string;
  start_date: string;
  end_date: string;
  location: string;
  is_internal = 0;
  max_people: number;
  subscribed?: number;
  link: string;
  preview_text: string;
  text_mobile: string;
  text_desktop: string;
  created_at: Date;
  updated_at: Date;
  metadata?: string;
  alternate_link?: string;


  constructor(partial?: Partial<Event>) {
    super(partial);

    this.is_internal = Number(partial.is_internal);
  }

  isFull() {
    if (!this.is_internal) {
      return false;
    }
    return this.subscribed >= this.max_people;
  }

  isPast() {
    return new Date(this.end_date) < new Date();
  }

  toJSONLD() {
    return {
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: this.title,
      startDate: this.start_date,
      endDate: this.end_date,
      location: this.link,
      description:
        this.preview_text ??
        new ShortenPipe().transform(
          new StripTagsPipe().transform(this.text_desktop)
        ),
      offers: {
        '@type': 'Offer',
        url: this.link,
      },
    };
  }
}
