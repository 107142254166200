import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { URL_ADMIN_LOGOUT } from '../shared/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private tokenExtractor: HttpXsrfTokenExtractor
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    const token = this.tokenExtractor.getToken();
    if (token !== null) {
      request = request.clone({ setHeaders: { "X-XSRF-TOKEN": token } });
    }

    return next.handle(request).pipe(
      tap((response: HttpResponse<any>) => {
        if (response.body && response.body.appUser) {
          this.authService.setUser(response.body.appUser);
        }
      }),
      catchError((err) => {
        if (request.url !== URL_ADMIN_LOGOUT && err.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.logout('admin');
        }

        return throwError(err);
      })
    );
  }
}
